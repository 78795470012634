<script lang="ts" setup>
import SpecialistNavLink from '~/components/specialist/SpecialistNavLink.vue'
import Page from '~/enums/page'
import { useSpecialistsStore } from '~/store/specialists'
import { useUserStore } from '~/store/user'

const userStore = useUserStore()
const { isSpecialist } = useUserStore()
const specialistsStore = useSpecialistsStore()

const items = computed(() => [
  ...(!isSpecialist || !specialistsStore.authSpecialistId ? [{
    isActive: true,
    link: isSpecialist ? Page.SpecialistProfile : Page.Partner,
    title: 'Стать специалистом',
  }] : []),
])
</script>

<template>
  <nav>
    <SpecialistNavLink
      v-for="(x, i) in items"
      :key="i"
      :is-active="x.isActive"
      :link="x.link"
      :title="x.title"
    />
  </nav>
</template>
